import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  useTheme,
} from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { FilterExpression } from '@endorlabs/filters';
import {
  BarChartStackedVertical,
  Color,
  Colors,
  EmptyState,
  ExclusiveToggleButtonGroup,
  useExclusiveToggleButtonGroup,
  ValueFormatter,
} from '@endorlabs/ui-common';

import { useDashboardVulnerabilitiesTimeTrendData } from '../hooks';

export const DashboardVulnerabilitiesTrend = ({
  namespace,
  baseFilter,
}: {
  namespace: string;
  baseFilter: FilterExpression;
}) => {
  const { palette } = useTheme();
  const { formatNumber } = useIntl();

  const { getToggleButtonGroupProps, value: vulnState } =
    useExclusiveToggleButtonGroup('open', [
      {
        value: 'open',
        children: <FM defaultMessage="Newly Discovered" />,
      },
      {
        value: 'resolved',
        children: <FM defaultMessage="Resolved" />,
      },
    ]);

  const { vulnOverTimeChartData, isEmpty, isLoading } =
    useDashboardVulnerabilitiesTimeTrendData({
      namespace,
      baseFilter,
      vulnState,
    });

  const getValueFormat: ValueFormatter = (value) => {
    if ('string' === typeof value) return value;
    return formatNumber(value, {
      notation: 'compact',
    });
  };

  return (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible', // HACK: prevent cutting off tooltip overlay at bottom of chart
        '& .MuiCardContent-root': {
          flexGrow: 1,
        },
      }}
    >
      <CardHeader title={<FM defaultMessage="Vulnerabilities over Time" />} />
      <CardContent>
        <Stack gap={4} height="100%">
          <ExclusiveToggleButtonGroup {...getToggleButtonGroupProps()} />
          <Stack alignItems="center" height="100%" justifyContent="center">
            {isLoading && <CircularProgress />}

            {isEmpty && !isLoading && (
              <EmptyState
                size="small"
                textAlign="center"
                title={
                  <FM defaultMessage="No records match the filter criteria" />
                }
              />
            )}
            {!isEmpty && !isLoading && (
              <ParentSize debounceTime={100}>
                {({ width: visWidth, height: visHeight }) => (
                  <BarChartStackedVertical
                    data={vulnOverTimeChartData}
                    width={visWidth}
                    height={visHeight}
                    xKey="label"
                    yKeys={FINDING_LEVELS.slice().reverse()}
                    axisLabel="Counts"
                    axisLeft
                    axisTickFormat={getValueFormat}
                    axisBottom
                    barColors={
                      [
                        palette.severity.low,
                        palette.severity.medium,
                        palette.severity.high,
                        palette.severity.critical,
                      ] as Color[]
                    }
                    margin={{
                      bottom: 24,
                      left: 60,
                      top: 16,
                      right: 0,
                    }}
                    axisColor={Colors.BLACK}
                  />
                )}
              </ParentSize>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
